import CopyrightIcon from '@mui/icons-material/Copyright';
import SvgIcon from '@mui/material/SvgIcon';
import { useMediaQuery } from 'react-responsive';

const Footer = () => {
    const isMobile = useMediaQuery({ maxWidth: 1200 });
    const iconStyle = {
        fontSize: isMobile ? '1.5rem' : '16px' , // Adjust the font size as needed
        color: 'white',
        marginRight: '4px', // Set the color to white
      };


    return (    
        <div  className="Footer" style={{ height: isMobile ? '3.5rem' : '2.5rem' }}>
            <SvgIcon component={CopyrightIcon} style={iconStyle}  fontSize="large" /> 
            <p style={{ fontSize: isMobile ? '1.5rem' : '0.9rem' }}>Moroder-Taxi</p>
            <hr />
            <a href="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>
                <p style={{ fontSize: isMobile ? '1.5rem' : '0.9rem' }}>Privacy Policy</p>
            </a>
            <hr />
            <a href="/impressum" style={{ textDecoration: 'none', color: 'inherit' }}>
                <p style={{ fontSize: isMobile ? '1.5rem' : '0.9rem' }}>Impressum</p>
            </a>
        </div>
          )
          }
    
    
    export default Footer;
