import React, { useState, useEffect } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Element } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';


const Faq = ({ id }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const [faqData, setFaqData] = useState([]);
  useEffect(() => {
    setFaqData([
      {
        question: t('faq_question_1'),
        answer: t('faq_answer_1'),
        open: false,
      },
      {
        question: t('faq_question_2'),
        answer: t('faq_answer_2'),
        open: false,
      },
      {
        question: t('faq_question_3'),
        answer: t('faq_answer_3'),
        open: false,
      },
      {
        question: t('faq_question_4'),
        answer: t('faq_answer_4'),
        open: false,
      },
      {
        question: t('faq_question_5'),
        answer: t('faq_answer_5'),
        open: false,
      },
      {
        question: t('faq_question_6'),
        answer: t('faq_answer_6'),
        open: false,
      },
      {
        question: t('faq_question_7'),
        answer: t('faq_answer_7'),
        open: false,
      },
      {
        question: t('faq_question_8'),
        answer: t('faq_answer_8'),
        open: false,
      },
      {
        question: t('faq_question_9'),
        answer: t('faq_answer_9'),
        open: false,
      },
      {
        question: t('faq_question_10'),
        answer: t('faq_answer_10'),
        open: false,
      },
    ]);
  }, [t]);



    const toggleAccordion = (index) => {
        setFaqData((prevFaqData) =>
          prevFaqData.map((item, i) =>
            i === index ? { ...item, open: !item.open } : { ...item, open: false }
          )
        );
      };
    
      return (
        <Element name={id}>
        <div  className="ContainerFaq">
          <div className="SubContainerFaq">
            <div className="PageHeaderFaq">
              <p style={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>FAQ</p>
            </div>
            <div className="FaqTextContainer">
                <p className='Title' style={{ fontSize: isMobile ? '4rem' : '3rem' }}>FAQ</p>
              {faqData.map((faqItem, index) => (
                <div key={index} className="faq-item">
                  <button className="accordion" onClick={() => toggleAccordion(index)}>
                    <p className="Question" style={{ fontSize: isMobile ? '2rem' : '1rem' }}>{faqItem.question}</p>
                    <FontAwesomeIcon icon={faqItem.open ? faChevronUp : faChevronDown} />
                  </button>
                  {faqItem.open && (
                    <div className="panel">
                      <p className="Answer" style={{ fontSize: isMobile ? '2rem' : '1rem' }}>{faqItem.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        </Element>
      );
    };
    
    export default Faq;