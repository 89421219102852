import { Divider, Grid,Container, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';



const PrivacyPage = () => {

    const isMobile = useMediaQuery({ maxWidth: 1200 });


    return (
        <Container>
            <Grid container spacing={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start', // Align items at the start vertically
                minHeight: '100vh',
                paddingTop: 4, // Add some padding at the top if necessary
            }}>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '4rem', color:"#023e48" , textAlign: 'center'}} >Privacy Policy</Typography>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left', paddingTop: "3rem", paddingBottom:"0rem"}} >Taxi-Moroder understands that you care about how your information is used and shared. This Privacy Policy outlines what information we collect from you when you use the Taxi Service, how we use that information, and how you can manage the collection, correction, and/or deletion of your information. We will not use or share your information with anyone except as described in this Privacy Policy. Please note that this policy does not apply to information collected through other means (including offline) or from other sources. 
                
                </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Owner of Treatment</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >The Owner of the Treatment of your personal data on this website is: <br /> Taxi Moroder  <br /> Via Val 1 <br /> 39047 S.Cristina - BZ <br /> +3934229118790 <br /> info@taximoroder.it</Typography>
                
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Collected Information</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >When you request a Service on our website, you provide us with personal information such as your name, address, email address, and phone number. We do not collect information from social networks. Additionally, if you use our Service, you may share geolocation information based on your software settings. If you correspond with us via email, we may retain the content of your messages, your email address, and our responses. <br />
                Cookies Information: When you visit our Service, we may send one or more cookies—a small text file containing a string of alphanumeric characters—to your computer. These cookies uniquely identify your browser and help Rome2Rio assist you with faster logins and improved navigation through the site. Cookies may also convey anonymous information about how you browse the Service. They do not collect personal information about you.
                There are two types of cookies we use: Persistent Cookies and Session Cookies. Persistent cookies remain on your hard drive after you close your browser and may be used by your browser on subsequent visits to the site. You can remove persistent cookies by following your web browser’s instructions. Session cookies are temporary and disappear after you close your browser. You can reset your web browser to refuse all cookies or to indicate when a cookie is being sent. However, disabling cookies may affect the functionality of some features of the Service.
                </Typography>
                
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Use of Information</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >We use the personal information you submit to operate, maintain, and provide to you the features and functionality of the  Booking-Service. By providing Taxi-Moroder your email address and mobile phone number, you consent to our using the email address or mobile phone number to send you Service-related notices via email or SMS. Additionally, the data collected is used to fulfill legal obligations and to monitor system efficiency.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Processing and protection of Information</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >Your personal data are processed both manually and electronically, primarily through automated processes. We use databases  that may be managed by us or third parties. Each processing method ensures the confidentiality and respect of the data. We store such data and general information in databases. To provide you with a seamless navigation experience, we need to collect certain technical data necessary for the website's correct operation, including browser type and version, operating system, referrer website, date and time of access, IP address, and other similar data and information. The legal basis for this processing is Article 6 of the GDPR.  The data is processed is such a way that a high level of security is given.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Retention period of data</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >Under the General Data Protection Regulation (GDPR) of the European Union, the retention period for personal data should be based on the necessity to fulfill the purpose for which the data was collected. Personal data should not be kept longer than necessary for these purposes. We retain personal data for the duration required to fulfill the service and for a period afterward to address any legal obligations. Users have the right to withdraw their consent to process their information at any time. Once consent is withdrawn, the corresponding data is deleted immediately. The data we collect adheres to the principle of data minimization, meaning we only collect what is necessary to fulfill the intended purpose.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Compromise of Personal Information</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >If personal information is compromised due to a security breach, Taxi-Moroder will promptly notify all relevant government agencies and the individuals whose personal information has been affected. This notification will be carried out in accordance with the procedures outlined in this Privacy Policy or as required by applicable law.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Cookies</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >We use cookies to: (a) remember information so you don't have to re-enter it during your visit or next time you visit the site; (b) provide custom, personalized content and information; (c) monitor the effectiveness of our Service; (d) monitor aggregate metrics such as total number of visitors, traffic, and demographic patterns; (e) diagnose or fix technology problems reported by our users associated with certain IP addresses; (f) help you efficiently access your information after you sign in.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >How can I withdraw my consent to the use of Cookies?</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >When you first access our services, we ask you to confirm if you’re ok with us using Cookies in line with this policy. If you don’t agree to our use of these technologies, or you change your mind at a later date, you can either withdraw your consent using the methods set out below or, alternatively, stop using our services.</Typography>
                <Typography variant="h5" gutterBottom style={{ fontSize: isMobile ? '2.5rem' : '1.5rem', color:"black", textAlign: 'left' }}>
                    <b>Browser Settings</b>
                </Typography>
                <Typography variant="h6" gutterBottom style={{ fontSize: isMobile ? '2rem' : '1rem', color:"black", textAlign: 'left' }}>
                    Most web browsers allow you to manage your cookie preferences, including withdrawing consent for cookies. Here’s how you can do it:
                    <ol style={{ marginLeft: '2rem' }}>
                        <li>Open your web browser (e.g., Chrome, Firefox, Safari, Edge).</li>
                        <li>Go to the browser’s settings or preferences menu. This is usually accessible via the three-dot or three-line icon in the top right corner.</li>
                        <li>Navigate to the section related to privacy and security.</li>
                        <li>Look for options like “Cookies and other site data” or “Content settings.”</li>
                        <li>Select the option to block third-party cookies or all cookies. You can also choose to clear cookies every time you close the browser.</li>
                        <li>Save or apply the changes to ensure your preferences are updated.</li>
                    </ol>
                </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Changes to Our Privacy Policy</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >If we change our privacy policies and procedures, we will post those changes on the Service to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black", textAlign: 'right', fontStyle: 'italic', paddingTop: '2rem' }}>
        This Privacy Policy was last revised on May 25, 2024.
    </Typography>
    <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '2rem' : '0.8rem', color:"black", textAlign: 'right', fontStyle: 'italic', paddingTop: '2rem' }}>
     Privacy Policy version 1.0
    </Typography>
                </Grid>
                

            </Grid>
        </Container>
        
    );
}

export default PrivacyPage;