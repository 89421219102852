import React from 'react';
import interior from './Interior.jpeg';
import car from './Car.jpeg';
import picture3 from './Slide3.jpeg';

const pictures = [picture3, car, interior];
const captions = [
  { title: "Experience Luxury Travel.", description: "Unparalleled comfort for those who value every journey." },
  { title: "Comfort Meets Elegance.", description: "Experience luxury interiors designed for the ultimate comfort." },
  { title: "Drive Your Passion.", description: "Vist the most spectacular places in the Dolomites." }
];
const delay = 10000;

const Slideshow = ({ id }) => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === pictures.length - 1 ? 0 : prevIndex + 1)),
      delay
    );

    return () => {};
  }, [index]);

  return (
    <div id={id} className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {pictures.map((pic, idx) => (
          <div className="slideContainer" key={idx}>
            <img className="slide" src={pic} alt={`Slide ${idx}`} />
            <div className="textOverlay">
              <h1>{captions[idx].title}</h1>
              <p>{captions[idx].description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="slideshowDots">
        {pictures.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => setIndex(idx)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
