import { Divider, Grid,Container, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';



const ImpressumPage = () => {

    const isMobile = useMediaQuery({ maxWidth: 1200 });


    return (
        <Container>
            <Grid container spacing={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start', // Align items at the start vertically
                minHeight: '100vh',
                paddingTop: 4, // Add some padding at the top if necessary
            }}>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '4rem', color:"#023e48" , textAlign: 'center'}} >Impressum</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Owner of Website</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} > Taxi Moroder  <br /> Via Val 1 <br /> 39047 S.Cristina - BZ <br /> +3934229118790 <br /> info@taximoroder.it</Typography>
                
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >EU Dispute Resolution</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >In accordance with the Regulation on Online Dispute Resolution in Consumer Affairs (ODR Regulation), we would like to inform you about the Online Dispute Resolution platform (OS Platform). <br /> <br /> Consumers have the option to submit complaints to the Online Dispute Resolution platform of the European Commission at <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>http://ec.europa.eu/odr?tid=221096405</a>. You can find the necessary contact details in our legal notice above. <br /> <br /> However, we would like to inform you that we are neither willing nor obligated to participate in dispute resolution proceedings before a consumer arbitration board.
                </Typography>
                
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Liability for Content on this Website</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >We continuously develop the content of this website and strive to provide accurate and up-to-date information. Unfortunately, we cannot accept liability for the correctness of all content on this website, especially for content provided by third parties.

If you notice any problematic or illegal content, please contact us immediately. You can find the contact details in our legal notice above.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Liability for Links on this Website</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >Our website contains links to other websites, for whose content we are not responsible. According to § 17 ECG, we are not liable for linked websites as we had and have no knowledge of illegal activities, no illegalities have been apparent to us so far, and we would remove links immediately upon becoming aware of any illegalities.

If you notice any illegal links on our website, please contact us. You can find the contact details in the legal notice.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Copyright Notice</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >All content on this website (images, photos, texts, videos) is subject to copyright. If necessary, we will take legal action against the unauthorized use of parts of the content of our site.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" , textAlign: 'left'}} >Image Credits</Typography>
                <Divider sx={{ my: 2, borderColor: 'grey', borderBottomWidth: 4 }} />
                <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.2rem', color:"black" , textAlign: 'left'}} >The images, photos, videos, and graphics on this website are protected by copyright.</Typography>
                </Grid>
            </Grid>
        </Container>
        
    );
}

export default ImpressumPage;