import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Button, Box, Typography } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const URL_PROXY = process.env.REACT_APP_SERVER

const Cancel = () => {
    const { t } = useTranslation();

    const isMobile = useMediaQuery({ maxWidth: 1200 });
    const iconStyles = { fontSize: isMobile ? 45 : 25};

    // Retrieve id from URL 
    const navigate = useNavigate();
    const { bookingId } = useParams();
    const [error, setError] = useState(''); 
    const [bookingDetails, setBookingDetails] = useState({}); // Declare useState at the top level



      const fetchBookingDetails = useCallback(async () => {
        try {
            const response = await axios.get(`${URL_PROXY}/booking/${bookingId}`);
            setBookingDetails(response.data.booking);
        } catch (error) {
            setError('Failed to retrieve booking details.')
        }
    }, [bookingId]);  // Dependency array for useCallback


    useEffect(() => {
        fetchBookingDetails();
    }, [fetchBookingDetails]);

    //Error handling for getBooking
    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }

    // If Booking NULL 
    if (!bookingDetails) {
        return <h1>No Booking Found!</h1>;
    }

    const { firstName, lastName, adress, country, phonenumber, email, origin, destination, persons, date, time, price, _id } = bookingDetails;

    
    const handleButtonClick = async () => {
        if (window.confirm("Are you sure you want to cancel the booking?")) {
            try {
                const response = await axios.get(`${URL_PROXY}/cancelBooking/${bookingId}`);
                alert('Booking cancelled successfully.');
                console.log(response);
                navigate('/home');
            } catch (error) {
                let errorMessage;
                if (error.response && error.response.data) {
                    errorMessage = error.response.data.message || 'An error occurred';
                } else {
                    errorMessage = 'Failed to cancel booking.';
                }
                alert(errorMessage);
            }
        }
    };




    

    return (
        <div className="Cancel">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={10}>
            </Box>
             <Grid container justifyContent="center" sx={{ maxWidth: '80%', margin: '0 auto', marginTop: "3rem" }}>
            <Grid item xs={12} lg={6} sx={{ marginTop: '13px' }}> {/* Second grid for additional content */}
            <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2}sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
              <Typography variant="h5" color = "#023e48" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.5rem', color:"#023e48" }}> {t('cancel_ride_summary')}</Typography>
             
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <PlaceOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>{t('cancel_start')} <span style={{ color: 'black', fontSize: isMobile ? '2rem' : '1.2rem'}}>{origin}</span></Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <PlaceOutlinedIcon style={iconStyles} />
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>{t('cancel_destination')} <span style={{ color: 'black', fontSize: isMobile ? '2rem' : '1.2rem'}}>{destination}</span></Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <CalendarMonthOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>{date}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <AccessTimeOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>{time}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Person2OutlinedIcon style={iconStyles} />
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>{persons}</Typography>
              </Box>
     
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>Total:</Typography>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '4rem' : '1.5rem'}}>{price}€</Typography>
              </Box>
            </Box>
            </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ maxWidth: '80%', margin: '0 auto', marginTop: "2rem" }}>
            <Grid item xs={12} lg={6} sx={{ marginTop: '13px' }}> {/* Second grid for additional content */}
            <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2}sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" color = "#023e48" gutterBottom style={{ fontSize: isMobile ? '2.5rem' : '1.5rem', color:"#023e48" }}>
                {t('cancel_booking_details')}
                </Typography>
                <Typography variant="h5" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>
                   ID:{_id}
                </Typography>
            </Box>
         
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{firstName} {lastName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{adress}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{country}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{phonenumber}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{email}</Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" m={5}>  {/* Adding margin for better spacing */}
                <Button variant="contained" size="large" onClick={handleButtonClick} sx={{ backgroundColor: '#FF0000', color: 'FFFFF' , height: isMobile ? 140 : 70 , width: isMobile ? 600 : 300 ,fontSize:isMobile ? 40 : 25  }}>
                {t('cancel_cancel_booking')}
                </Button>
            </Box>
            </Grid>
            </Grid>
        </div>
    );
}

export default Cancel;