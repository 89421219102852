import { Element } from 'react-scroll'; // Import Element from react-scroll
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Maestro from './Maestro.png'; 
import ApplePay from './ApplePay.png'; 
import Visa from './Visa.png'; 
import MasterCard from './MasterCard.png'; 
import Cash from './Cash.png'; 
import AE from './american-express.png'; 
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';







const General = ({id}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  return (
    <Element name={id}> {/* Wrap the entire content with Element and assign name prop */}
      <div  className="ContainerGeneral">
        <div className="SubContainerGeneral">
          <div className="GeneralHeader">
            <p>{t('demand_header')}</p>
          </div>
          <div className="text1">
            <p>{t('demand_text')} </p>
            
          </div>
          <div className="WhatsappIcon">
          </div>
          <div className="PhoneIcon">
          </div>
          <div className="text2">
            <a style={{  marginLeft:isMobile ? 300 : -20 }} href="tel:+393429118790" className="no-link-style">+39 3429118790</a> 
            <div className="icons">
            <a href="https://wa.me/393429118790" target="_blank" rel="noopener noreferrer" >
                <WhatsAppIcon style={{ fontSize: isMobile ? 80 : 50, color: 'green' , marginLeft:isMobile ? 370 : 50 }} />
            </a>
            <a href="tel:+393429118790">
                <LocalPhoneOutlinedIcon style={{ fontSize: isMobile ? 80 : 50, marginLeft:isMobile ? 50 : 50 , color: 'green' }} />
            </a>
            </div>
            <div className="payment" style={{  marginLeft:isMobile ? 300 : 0 }}>
            <p style={{  marginLeft:isMobile ? 0 : 0 }}>{t('demand_payments')}</p>
            <div className="paymentMethods">
            <img src={Maestro} alt="Visa" style={{ width: 50, height: 50, marginLeft:-50 }} /> {/* Adjust path as necessary */}
            <img src={Visa} alt="Visa" style={{ width: 50, height: 50,marginLeft:10 }} /> {/* Adjust path as necessary */}
            <img src={AE} alt="Cash" style={{ width: 40, height: 40, marginLeft:10, marginBottom:5 }} /> {/* Adjust path as necessary */}
            <img src={MasterCard} alt="Visa" style={{ width: 70, height: 70, marginLeft:5 , marginBottom:-10}} /> {/* Adjust path as necessary */}
            <img src={ApplePay} alt="Visa" style={{ width: 50, height: 50, marginLeft:10 }} /> {/* Adjust path as necessary */}
            <img src={Cash} alt="Cash" style={{ width: 30, height: 30, marginLeft:10, marginBottom:13 }} /> {/* Adjust path as necessary */}
            </div>

            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default General;