import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Grid, Box, Button, Typography, Divider } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

const URL_PROXY = process.env.REACT_APP_SERVER

const getStatusStyle = (status) => {
    switch (status) {
        case 'CANCELLED':
            return { backgroundColor: '#ffcccc' }; // Light red for cancelled
        default:
            return {}; // Default, no additional styling
    }
};

const Admin = () => {
    const isMobile = useMediaQuery({ maxWidth: 1200 });
    const { key } = useParams();
    const [error, setError] = useState(''); 
    const [bookings, setBookings] = useState([]); 

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const response = await axios.get(`${URL_PROXY}/Admin/${key}`);
                setBookings(response.data.bookings);
            } catch (error) {
                setError('Failed to retrieve bookings.');
            }
        };
    
        fetchBookingDetails();
    }, [key]); 

    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }

    if (!bookings || bookings.length === 0) {
        return <h1>No Booking Found!</h1>;
    }

    const handleDoneClick = async (id) => {
        try {
            const response = await axios.get(`${URL_PROXY}/bookingStatus/${id}`);
            const updatedBooking = response.data.booking;
            setBookings(bookings.map(booking => booking._id === id ? updatedBooking : booking));
            alert("Booking marked as done.");
        } catch (error) {
            console.error('Failed to mark booking as done:', error);
            alert("Failed to update the booking status. Please try again.");
        }
    };

    const handleCancelClick = async (id) => {
        if (window.confirm("Are you sure you want to cancel this booking?")) {
            try {
                const response = await axios.get(`${URL_PROXY}/cancelBooking/${id}`);
                const updatedBooking = response.data.booking;
                setBookings(bookings.map(booking => booking._id === id ? updatedBooking : booking));
            } catch (error) {
                console.error('Failed to cancel booking:', error);
                alert("Failed to cancel the booking. Please try again.");
            }
        }
    };

    // Mobile Layout
    const renderMobileLayout = () => (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h2" sx={{ mb: 2, marginBottom: "60px", marginTop: "20px", textAlign: 'center' }}>
                Admin Panel: Bookings
            </Typography>
            {bookings.map((booking) => (
                <Box key={booking._id} sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    border: '2px solid #ccc',
                    backgroundColor: 'background.paper',
                    ...getStatusStyle(booking.status)
                }}>
                    <Typography variant="h6">{booking.firstName} {booking.lastName}</Typography>
                    <Typography>Email: {booking.email}</Typography>
                    <Typography>Phone: {booking.phonenumber}</Typography>
                    <Typography>Address: {booking.adress}</Typography>
                    <Typography>Nationality: {booking.country}</Typography>
                    <Divider sx={{ my: 4 }} />
                    <Typography>Start: {booking.origin}</Typography>
                    <Typography>Destination: {booking.destination}</Typography>
                    <Typography>Date: {booking.date} at {booking.time}</Typography>
                    <Typography>Passengers: {booking.persons}</Typography>
                    <Typography>Status: {booking.status}</Typography>
                    <Typography>Extras: {booking.amenities}</Typography>
                    <Typography>INFO: {booking.info}</Typography>
                    <Typography sx={{ color: 'black', fontSize: '1.4rem' }}>Price: €{booking.price}</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="outlined" onClick={() => handleDoneClick(booking._id)} sx={{ fontSize: '2rem', padding: '12px 24px' }}>DONE</Button>
                        <Button variant="outlined" onClick={() => handleCancelClick(booking._id)} sx={{ ml: 2 , fontSize: '2rem', padding: '12px 24px' , borderColor: 'red', color: 'red' }}>Cancel Reservation</Button>
                    </Box>
                </Box>
            ))}
        </Box>
    );

    // Desktop Layout
    const renderDesktopLayout = () => (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" sx={{ mb: 2, marginBottom: "60px", marginTop: "20px", textAlign: 'center' }}>
                Admin Panel: Bookings
            </Typography>
            {bookings.map((booking) => (
                <Grid container spacing={2} key={booking._id} sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    border: '2px solid #ccc',
                    backgroundColor: 'background.paper',
                    ...getStatusStyle(booking.status)
                }}>
                    {/* First Column */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6">{booking.firstName} {booking.lastName}</Typography>
                        <Typography>Email: {booking.email}</Typography>
                        <Typography>Phone: {booking.phonenumber}</Typography>
                        <Typography>Address: {booking.adress}</Typography>
                        <Typography>Nationality: {booking.country}</Typography>
                    </Grid>
                    
                    {/* Second Column */}
                    <Grid item xs={12} sm={4}>
                        <Typography>From: {booking.origin}</Typography>
                        <Typography>To: {booking.destination}</Typography>
                        <Typography>Date: {booking.date} at {booking.time}</Typography>
                        <Typography>Passengers: {booking.persons}</Typography>
                        <Typography>Status: {booking.status}</Typography>
                        <Typography>Extras: {booking.amenities}</Typography>
                        <Typography>INFO: {booking.info}</Typography>
                        <Typography sx={{ color: 'black', fontSize: '1.4rem' }}>Price: €{booking.price}</Typography>
                    </Grid>
                    
                    {/* Third Column */}
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant="outlined" onClick={() => handleDoneClick(booking._id)}>DONE</Button>
                        <Button variant="outlined" onClick={() => handleCancelClick(booking._id)} sx={{ ml: 2,  borderColor: 'red', color: 'red'  }}>Cancel Reservation</Button>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );

    return isMobile ? renderMobileLayout() : renderDesktopLayout();
};

export default Admin;
