import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from './logo.png';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LanguageIcon from '@mui/icons-material/Language';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink } from 'react-router-dom'; // Import Link from react-router-dom

const pages = [{ name: 'Home', path: '/' }]; // Update page to include name and path
const languages = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'it', label: 'Italiano' }
];

function NavBarSite() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const handleSetLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
    handleCloseLangMenu();
  };

  return (
    <AppBar position="sticky"
    sx={{
      px: 2,
      backgroundColor: '#023e48',
      fontFamily: 'DM Sans, sans-serif',
      height: isMobile ? '140px' : '80px'
    }}>
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: { xs: 'flex-start'},
          alignItems: 'center'
        }}>
          <img src={Logo} alt="Logo" style={{ height: isMobile ? 120 : 70, width: isMobile ? 160 : 90, marginTop: 5}} />
          <span style={{ color: '#cdb838', fontSize: isMobile ? '30px' : '18px', marginTop: 7, marginLeft: -10 }}>Taxi-Moroder</span>
        </Box>

        {/* Display the language icon always */}
        <Box sx={{ display: 'flex', alignItems: 'left' , marginLeft: 'auto' }}>
          <IconButton
            sx={{ color: 'white' }}
            onClick={handleOpenLangMenu}
          >
            <LanguageIcon sx={{ fontSize: isMobile ? 60 : 25 }} />
          </IconButton>
          <Menu
            id="menu-lang"
            anchorEl={anchorElLang}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElLang)}
            onClose={handleCloseLangMenu}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.code} onClick={() => handleSetLanguage(lang.code)} sx={{ fontSize: isMobile ? '40px' : 'inherit', py: isMobile ? 2 : 'inherit' }}>
                {lang.label}
              </MenuItem>
            ))}
          </Menu>

          {/* Display the menu links and hamburger menu */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              {pages.map((page) => (
                <Button
                  component={RouterLink}
                  to={page.path}
                  key={page.name}
                  sx={{ color: 'white', fontFamily: 'DM Sans, sans-serif', fontSize: isMobile ? '40px' : 'inherit' }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
          )}

          {isMobile && (
            <Box sx={{ flexGrow: 0, display: 'flex' }}>
              <IconButton
                size="large"
                aria-label="dropdown"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ fontSize: 80 }} />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <MenuItem
                    component={RouterLink}
                    to={page.path}
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    sx={{ fontFamily: 'DM Sans, sans-serif', fontSize: '40px', py: 2 }}
                  >
                    {page.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
);
}

export default NavBarSite;
